import React from 'react';
import { ListGroup, Image, Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis } from '@fortawesome/free-solid-svg-icons';

type ChatListProps = {
  onChatClick: (id: string) => void;
  selectedChat?: any;
};

const mockChats = [
  { 
    id: '1', 
    name: 'Ricko Ferdinand Malik', 
    lastMessage: 'Okay siap sama sama brother', 
    time: '10:58 AM',
    unread: 1,
    statusIcon: 'doubleCheck.svg', // Ícone de double check
    avatar: 'https://via.placeholder.com/50', // Placeholder para avatar
  },
  { 
    id: '2', 
    name: 'George Alexander Martin', 
    lastMessage: 'Siapp nuhun pisan brader', 
    time: '10:56 AM',
    unread: 0,
    statusIcon: 'doubleCheck.svg', // Ícone de double check
    avatar: 'https://via.placeholder.com/50', // Placeholder para avatar
  },
  { 
    id: '3', 
    name: 'Sheira Azzahra Putri', 
    lastMessage: 'Kita keliling kota bogor aja yuu', 
    time: '10:52 AM',
    unread: 0,
    statusIcon: 'doubleCheck.svg', // Ícone de double check
    avatar: 'https://via.placeholder.com/50', // Placeholder para avatar
  },
  // Adicione mais mock data conforme necessário
];

const ChatList: React.FC<ChatListProps> = ({ onChatClick, selectedChat }) => {
  return (
    <ListGroup>
      <ListGroup.Item 
        style={{
          backgroundColor: 'white',
          border: '0',
          borderRadius: '0',
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          height: '80px'
        }}
      >
        <FontAwesomeIcon icon={faEllipsis} className="text-muted fs-5" />
      </ListGroup.Item>
      {mockChats.map((chat) => (
        <ListGroup.Item 
          action 
          onClick={() => onChatClick(chat.id)} 
          key={chat.id} 
          className={`d-flex align-items-center ${selectedChat === chat.id ? 'active-chat' : ''}`}
          style={{
            backgroundColor: selectedChat === chat.id ? '#f8f9fa' : 'white',
            border: '0',
            borderRadius: '0',
          height: '80px'
        }}
        >
          <Image src={chat.avatar} roundedCircle className="mr-3" style={{ width: '50px', height: '50px', marginRight: '10px' }} />
          <div className="flex-grow-1">
            <div className="d-flex justify-content-between">
              <h5 className="mb-0">{chat.name}</h5>
              <small className="text-muted ">{chat.time}</small>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <small className="text-muted">
              <img src={process.env.PUBLIC_URL + '/assets/icons/doubleCheck.svg'} alt="check" /> {chat.lastMessage}
              </small>
              {chat.unread > 0 && (
                <Badge pill bg="primary" className="ml-2" style={{ fontSize: '0.75rem' }}>
                  {chat.unread}
                </Badge>
              )}
            </div>
          </div>
        </ListGroup.Item>
      ))}
    </ListGroup>
  );
};

export default ChatList;
