import React from 'react';
import Sidebar from '../../components/specific/Sidebar';
import Main from '../../components/specific/Main';

const Persona = () => {

    return (
        <Main></Main>
    );
};

export default Persona;