import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Main from '../../components/specific/Main';
import ChatList from '../../sections/Messages/ChatList';
import ChatWindow from '../../sections/Messages/ChatWindow';
import useWindowSize from '../../hooks/UseWindowSize';
import './Messages.css'; // Importa o arquivo de estilo

const Messages = () => {
  const { chatId } = useParams();
  const navigate = useNavigate();
  const { width } = useWindowSize(); // Hook para pegar o tamanho da tela
  const [selectedChat, setSelectedChat] = useState(chatId || null);
  const [isTransitioning, setIsTransitioning] = useState(false); // Controla se a transição está ocorrendo
  
  const isMobile = width <= 992; // Definimos o modo mobile se a largura for menor que 992px

  useEffect(() => {
    if (!chatId && !isMobile) {
      // No desktop, se não tiver chatId na URL, redireciona para o primeiro chat
      navigate(`/messages/1`);
    }
  }, [chatId, isMobile, navigate]);

  const handleChatClick = (id: string) => {
    setIsTransitioning(true); // Inicia a transição
    setSelectedChat(id);
    navigate(`/messages/${id}`);
    setIsTransitioning(false); // Finaliza a transição
  };

  const handleBackClick = () => {
    setIsTransitioning(true); // Inicia a transição reversa
    navigate(`/messages`); // Volta para a lista de conversas no mobile
    setSelectedChat(null); // Remove o chat selecionado
    setIsTransitioning(false); // Finaliza a transição
  };

  return (
    <Main>
      <div className="chat-container">
        {/* Controlamos a visibilidade com classes CSS e o estado de transição */}
        <div className={`chat-list ${isMobile && selectedChat ? 'slide-out' : 'slide-in'}`}>
          <ChatList onChatClick={handleChatClick} selectedChat={selectedChat} />
        </div>

        <div className={`chat-window ${selectedChat ? 'slide-in' : 'slide-out'}`}>
          <ChatWindow chatId={selectedChat} onBackClick={handleBackClick} isMobile={isMobile} />
        </div>
      </div>
    </Main>
  );
};

export default Messages;
