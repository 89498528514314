import React from 'react';
import './style.css'
import Main from '../../components/specific/Main';
const Panel = () => {
    return (
        <Main></Main>
    );
};

export default Panel;
