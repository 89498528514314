import React from 'react';
import { Container, Row, Col, Form, Button, Image, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';
type ChatWindowProps = {
  chatId: any;
  onBackClick: () => void;
  isMobile: boolean;

};

const mockMessages: any = {
  '1': [
    { sender: 'Me', message: 'Sayang, besok kamu ada acara keluar ngga?', time: '10:56 AM', statusIcon: 'doubleCheck.svg', image: '' },
    { sender: 'Ricko Ferdinand Malik', message: 'Ngga ada acara sih besok, paling rebahan di rumah', time: '10:56 AM', image: 'https://via.placeholder.com/50' },
  ],
  '2': [
    { sender: 'Me', message: 'Siapp nuhun pisan brader', time: '10:56 AM', statusIcon: 'doubleCheck.svg', image: '' },
    { sender: 'George Alexander Martin', message: 'Siapp sama sama brother', time: '10:56 AM', image: 'https://via.placeholder.com/50' },
  ],
  '3': [
    { sender: 'Me', message: 'Kita keliling kota bogor aja yuu, sambil makan em algum lugar', time: '10:56 AM', statusIcon: 'doubleCheck.svg', image: '' },
    { sender: 'Sheira Azzahra Putri', message: 'Bolehh, aonde vamos?', time: '10:56 AM', image: 'https://via.placeholder.com/50' },
    { sender: 'Me', message: 'Kita keliling kota bogor aja yuu, sambil makan em algum lugar', time: '10:56 AM', statusIcon: 'doubleCheck.svg', image: '' },
    { sender: 'Sheira Azzahra Putri', message: 'Bolehh, aonde vamos?', time: '10:56 AM', image: 'https://via.placeholder.com/50' },
    { sender: 'Me', message: 'Kita keliling kota bogor aja yuu, sambil makan em algum lugar', time: '10:56 AM', statusIcon: 'doubleCheck.svg', image: '' },
    { sender: 'Sheira Azzahra Putri', message: 'Bolehh, aonde vamos?', time: '10:56 AM', image: 'https://via.placeholder.com/50' },
    { sender: 'Sheira Azzahra Putri', message: 'Bolehh, aonde vamos?', time: '10:56 AM', image: 'https://via.placeholder.com/50' },
    { sender: 'Sheira Azzahra Putri', message: 'Bolehh, aonde vamos?', time: '10:56 AM', image: 'https://via.placeholder.com/50' },
  ],
  // Adicione mais mock data conforme necessário
};

const ChatWindow: React.FC<ChatWindowProps> = ({ chatId, onBackClick, isMobile }) => {
  const messages = mockMessages[chatId];

  // Verificação se há mensagens disponíveis para o chatId
  if (!messages) {
    return <p></p>;
  }

  const chatUser = messages[1]?.sender || "Usuário";
  const userImage = messages[1]?.image || "";

  return (
    <Container fluid className="d-flex flex-column" style={{ height: '100%' }}>

      <Row className="border-bottom py-2">
        <Col className="d-flex align-items-center">
          {isMobile && (
            <Button variant="link" onClick={onBackClick} className="me-2">
              <FontAwesomeIcon icon={faArrowLeftLong} className="text-muted fs-5" />
            </Button>
          )}
        </Col>
      </Row>

      {/* Cabeçalho */}
      <Row className="border-bottom py-2">
        <Col className="d-flex align-items-center">
          <Image
            src={userImage}
            alt="Avatar"
            width="40"
            height="40"
            className="rounded-circle"
            style={{ marginRight: '10px' }}
          />
          <div className="ml-3">
            <h6 className="mb-0">{chatUser}</h6>
            <small className="text-muted">Online Now</small>
          </div>
        </Col>
      </Row>

      {/* Corpo do Chat */}
      <Row className="flex-grow-1 overflow-auto px-3 py-2">
        <Col>
          {messages.map((msg: any, index: any) => (
            <div
              key={index}
              className={`d-flex my-2 ${msg.sender === 'Me' ? 'justify-content-end' : 'justify-content-start'}`}
            >
              <div
                className={`p-3 rounded-lg position-relative ${msg.sender === 'Me' ? 'bg-primary text-white' : 'bg-light text-dark'}`}
                style={{
                  maxWidth: '75%',
                  borderRadius: '10px',
                  borderBottomRightRadius: msg.sender === 'Me' ? '0' : '10px',
                  borderBottomLeftRadius: msg.sender !== 'Me' ? '0' : '10px',
                }}
              >
                <div>{msg.message}</div>
                <div className="d-flex justify-content-end align-items-center mt-1">
                  <small>{msg.time}</small>
                  {msg.sender === 'Me' && (
                    <img src={process.env.PUBLIC_URL + '/assets/icons/' + msg.statusIcon} alt="Enviar" />
                  )}
                </div>
              </div>
            </div>
          ))}
        </Col>
      </Row>

      {/* Rodapé */}
      <Row className="mt-auto border-top p-3">
        <Col>
          <Form>
            <InputGroup>
              <Form.Control
                as="textarea"
                rows={1}
                placeholder="Type your message here"
                style={{ borderRadius: '25px 0 0 25px', resize: 'none', borderRight: 'none' }}
              />
              <InputGroup.Text
                style={{
                  backgroundColor: 'transparent',
                  borderRadius: '0 25px 25px 0',
                  border: '1px solid rgb(221, 221, 221)',
                  padding: '5px',
                  borderLeft: '0 solid transparent'
                }}
              >
                <Button variant="link" className="p-0">
                  <img
                    src={process.env.PUBLIC_URL + '/assets/icons/send.svg'}
                    alt="Send"
                    style={{ width: '24px', height: '24px' }}
                  />
                </Button>
              </InputGroup.Text>
            </InputGroup>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default ChatWindow;
